import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { ENGLISH_LANGUAGE_CODE } from './constants';

import enUS from './locales/en_US';
import itIT from './locales/it_IT';
import frCA from './locales/fr_CA';
import frFR from './locales/fr_FR';
import deDE from './locales/de_DE';
import esES from './locales/es_ES';
import svSE from './locales/sv_SE';
import nlNL from './locales/nl_NL';
import elGR from './locales/el_GR';
import zhCN from './locales/zh_CN';
import zhTW from './locales/zh_TW';

const TRANSLATIONS = {
  'en-US': enUS,
  'it-IT': itIT,
  'es-ES': esES,
  'de-DE': deDE,
  'fr-FR': frFR,
  'fr-CA': frCA,
  'sv-SE': svSE,
  'nl-NL': nlNL,
  'el-GR': elGR,
  'zh-CN': zhCN,
  'zh-TW': zhTW
};

const resources = Object.fromEntries(
  Object.entries(TRANSLATIONS).map(([key, value]) => [key, { translation: value }])
);

i18n.use(initReactI18next).init({
  resources,
  lng: ENGLISH_LANGUAGE_CODE,
  fallbackLng: ENGLISH_LANGUAGE_CODE,

  interpolation: {
    escapeValue: false
  }
});

export default i18n;
